import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {AppContext} from "../../Contexts/AppContext";
import {routers} from "../../../server/routes";
import {
    Switch,
    Route, useLocation
} from "react-router-dom";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://EverNUM.com/">
                EverNUM
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const mdTheme = createTheme();

export class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inviteId: 'def',
        };
    }

    // render view
    render() {
        return (
            <ThemeProvider theme={mdTheme}>
                <AppContext.Provider value={this.state}>
                    <Box sx={{display: 'flex'}}>
                        <Box
                            component="main"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                height: '100vh',
                                overflow: 'auto',
                            }}
                        >
                            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Switch>
                                            {
                                                routers.map((route, i) => (
                                                    <RouteWithSubRoutes key={i} {...route} />
                                                ))
                                            }
                                        </Switch>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{pt: 4}}/>
                            </Container>
                        </Box>
                    </Box>
                </AppContext.Provider>
            </ThemeProvider>
        );
    }
}

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => (
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}