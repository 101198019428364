import React from 'react';

const { Invite } = require( '../src/components/invite' );

export const routers = [
    {
        path: '/',
        exact: true,
        component: ()=>{return(<p>default</p>)},
    },
    {
        path: '/invite',
        exact: true,
        component: Invite,
    }
]

// module.exports = [...pathes];
